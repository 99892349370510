<script setup lang="ts">
import type { ProductVariant } from '~/utils/crystallize/product'
import { KsIcon } from '@aschehoug/kloss'
import { faCheck, faShoppingBasket } from '@fortawesome/pro-duotone-svg-icons'

const props = defineProps<{ product: ProductVariant, size?: 'small' | 'large' }>()

const { mutate: addToCart, isPending } = useAddToCart(props.product)

const { data: isInCart } = useCart(cart => cart?.cartItems?.some(it => it.productId === props.product.sku) || false)
</script>

<template>
  <Button variant="primary" color="moss" :size="size" :disabled="isPending || isInCart" @click="addToCart">
    <KsIcon
      :icon="isInCart ? faCheck : faShoppingBasket"
    />
    <span v-text="isPending ? 'Legger\xa0til' : isInCart ? 'Lagt\xa0til' : 'Legg\xa0i\xa0handlekurv'" />
    <Spinner v-if="isPending" color="carbon" size="small" class="!size-5" />
  </Button>
</template>
