<script setup lang="ts">
import type { Product } from '~/utils/crystallize/product'

const props = defineProps<{ product: Product }>()

const showImage = ref(true)

function handleImageError() {
  // If the image fails to load, we just remove it entirely
  showImage.value = false
}

const card = useProductCard(props.product)
</script>

<template>
  <div class="bg-white rounded-2xl py-xl px-l flex flex-col md:flex-row gap-l items-center">
    <CrystallizeImage v-if="showImage" :image="card.illustration" class="aspect-square size-[180px] px-xs flex items-center" @error="handleImageError" />
    <div class="flex flex-col w-full text-center items-center md:items-start md:text-start">
      <h4 v-if="card.title" class="mb-2xs" v-text="card.title" />
      <p v-if="card.subtitle" class="mb-s font-light" v-html="card.subtitle" />
      <NettbutikkProductVariantPrice v-if="card.variant" :variant="card.variant" interval="yearly" bold show-suffix class="mb-l text-body" />
      <div class="flex flex-wrap gap-s md:gap-m lg:gap-l items-center justify-center w-fit">
        <NettbutikkProductAddToCartButton v-if="card.variant" :product="card.variant" />
        <Button variant="secondary" :to="card.href">
          Les&nbsp;mer
        </Button>
      </div>
    </div>
  </div>
</template>
